import { useEffect, useRef, useState } from "react";
import { socket } from "../socket";
import { useNavigate } from "react-router-dom";
import Cookie, { CookieType } from "../components/Cookie";

const DisplayCookie: React.FC = () => {
  const [isCookieCracked, setIsCookieCracked] = useState<boolean>(false);
  const [fortuneCookieMessage, setFortuneCookieMessage] = useState<string>("");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const cookieRef = useRef<CookieType>(null);
  const navigate = useNavigate();

  const onCookieClicked = () => {
    if(cookieRef.current) {
      cookieRef.current.animateClick();
    }
  }

  const onTimeout = () => {
    if (timer) {
      clearTimeout(timer);
    }
    
    navigate("/exp/");
  }

  const onCookieCracked = (message: string) => {
    setIsCookieCracked(true);
    setFortuneCookieMessage(message);

    if (timer) {
      clearTimeout(timer);
    }

    const timeout = setTimeout(onTimeout, 30000);
    setTimer(timeout);
  }
  
  useEffect(() => {
    socket.on('cookieClicked', onCookieClicked);
    socket.on('cookieCracked', onCookieCracked);

    const timeout = setTimeout(onTimeout, 60000);
    setTimer(timeout);

    return () => {
      socket.off('cookieClicked', onCookieClicked);
      socket.off('cookieCracked', onCookieCracked);
    };
  }, []);

  return (
    <>
      {isCookieCracked ?
        <h1>{fortuneCookieMessage}</h1>
      :
        <>
          <h1>Clique sur le biscuit sur ton téléphone pour l'ouvrir</h1>
          <Cookie ref={cookieRef} />
        </>
      }
    </>
  );
};

export default DisplayCookie;