import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import WaitingRoom from './pages/WaitingRoom';
import DisplayCookie from './pages/DisplayCookie';
import MobileCookie from './pages/MobileCookie';
import { useEffect } from 'react';
import { socket } from './socket';

const App = () => {
  const routes = createBrowserRouter([
    {
      children: [
        {
          path   : '/exp',
          children: [
            {
              path   : '',
              element: (<WaitingRoom />),
            },
            {
              path   : 'cookie',
              element: (<DisplayCookie />),
            },
          ]
        },
        {
          path   : '/cookie/:roomId',
          element: (<MobileCookie />),
        },
      ],
    },
    {
      path   : '*',
      element: (<Navigate to="/" />),
    },
  ]);

  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);
  
  return (
    <main>
      <RouterProvider router={routes} />
    </main>
  );
}

export default App;
