import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { v4 as uuidv4 } from 'uuid';
import { socket } from "../socket";
import { useNavigate } from "react-router-dom";

const WaitingRoom: React.FC = () => {
  const [roomId, setRoomId] = useState<string>();
  const navigate = useNavigate();

  const onOtherClientConnected = () => {
    navigate("/exp/cookie");
  };

  const joinNewRoom = () => {
    const uuid = uuidv4();
    console.log("Room ID : ", uuid);
    setRoomId(uuid);
    socket.emit("join", uuid, "display");
  }

  useEffect(() => {
    joinNewRoom();

    socket.on('clientConnected', onOtherClientConnected);
    socket.on('roomFull', joinNewRoom);

    return () => {
      socket.off('clientConnected', onOtherClientConnected);
      socket.off('roomFull', joinNewRoom);
    };
  }, [socket.connected]);

  return (
    <>
      <h1>Scanne ce QR code et prend contrôle de ton destin</h1>
      <QRCode value={`${window.location.origin}/cookie/${roomId}`} />
    </>
  );
};

export default WaitingRoom;