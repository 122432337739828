import { useParams } from "react-router-dom";
import { socket } from "../socket";
import { useEffect, useRef, useState} from "react";
import Cookie, { CookieType } from "../components/Cookie";
import { Loader } from "../components/Loader";

const MobileCookie: React.FC = () => {
  const [isConnecting, setIsConnecting] = useState<boolean>(true);
  const [isRoomFull, setIsRoomFull] = useState<boolean>(false);
  const [isCookieCracked, setIsCookieCracked] = useState<boolean>(false);
  const [fortuneCookieMessage, setFortuneCookieMessage] = useState<string>("");
  const cookieRef = useRef<CookieType>(null);
  const { roomId } = useParams();
  
  const onRoomJoined = () => {
    setIsConnecting(false);
  }

  const onCookieCrackedMobile = (message: string) => {
    setIsCookieCracked(true);
    setFortuneCookieMessage(message);
  }

  const onRoomFull = () => {
    setIsRoomFull(true);
  }

  useEffect(() => {
    socket.emit("join", roomId, "mobile");

    socket.on('roomJoined', onRoomJoined);
    socket.on('roomFull', onRoomFull);
    socket.on('cookieCracked', onCookieCrackedMobile);

    return () => {
      socket.off('roomJoined', onRoomJoined);
      socket.off('roomFull', onRoomFull);
      socket.off('cookieCracked', onCookieCrackedMobile);
    };
  }, [roomId]);

  const onCookieClick = () => {
    socket.emit("cookieClick");
    if (cookieRef.current) {
      cookieRef.current.animateClick();
    }
  }
  
  return (
    <>
      {isConnecting ?
        isRoomFull ?
          <h1>Display is currently used</h1>
        :
          <Loader />
      :
        isCookieCracked ?
          <>
            <h1>{fortuneCookieMessage}</h1>
          </>
        :
          <>
            <h1>Clique sur le biscuit pour l'ouvrir</h1>
            <div onClick={onCookieClick}>
              <Cookie ref={cookieRef} />
            </div>
          </> 
      }
    </>
  );
};

export default MobileCookie;