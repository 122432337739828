import { Ref, forwardRef, useImperativeHandle, useRef } from "react";
import './Cookie.css';

interface Props {}

export interface CookieType {
  animateClick: () => void;
}

const Cookie = (props: Props, ref: Ref<CookieType>) => {
  const cookieRef = useRef<HTMLImageElement>(null);

  useImperativeHandle(ref, () => ({
    animateClick() {
      if(cookieRef.current) {
        cookieRef.current.animate(
          [
            { transform: "scale(1)" },
            { transform: "scale(0.8)" },
            { transform: "scale(1)" },
          ],
          {
            duration: 100,
            iterations: 1,
          },
        );
      }
    }
  }));

  return (
    <>
      <img ref={cookieRef} src="/fortune-cookie.svg" className="cookie" />
    </>
  );
};

export default forwardRef(Cookie);